<template>
  <div class="flow-root">
    <ul
      role="list"
      class="-mb-8"
    >
      <!-- No Comments -->
      <!-- <div class="flex items-center justify-center flex-1">
                      <div class="flex-1 text-center">
                          <fa-icon icon="comment" class="mx-auto text-gray-400" size="2x" aria-hidden="true" />
                          <h3 class="mt-2 text-sm font-medium text-gray-900">No comments</h3>
                          <p class="mt-1 text-sm text-gray-500">
                              Be the first to leave a comment for faster approvals and better feedback.
                          </p>
                      </div>
            </div>-->
      <li>
        <div class="relative pb-8">
          <span
            class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex items-start space-x-3">
            <div class="relative">
              <img
                class="flex items-center justify-center w-10 h-10 bg-gray-400 rounded-full ring-8 ring-white"
                src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=8&amp;w=256&amp;h=256&amp;q=80"
                alt
              >
            </div>
            <div class="flex-1 min-w-0">
              <div>
                <div class="text-sm">
                  <a
                    href="#"
                    class="font-medium text-gray-900"
                  >Eduardo Benz</a>
                </div>
                <p class="mt-0.5 text-sm text-gray-500">
                  Commented 6d ago
                </p>
              </div>
              <div class="mt-2 text-sm text-gray-700">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam.</p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="relative pb-8">
          <span
            class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex items-start space-x-3">
            <div>
              <div class="relative px-1">
                <div
                  class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full ring-8 ring-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5 text-gray-500"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="min-w-0 flex-1 py-1.5">
              <div class="text-sm text-gray-500">
                <a
                  href="#"
                  class="font-medium text-gray-900"
                >Hilary Mahy</a> assigned
                <a
                  href="#"
                  class="font-medium text-gray-900"
                >Kristin Watson</a>
                <span class="whitespace-nowrap">2d ago</span>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="relative pb-8">
          <span
            class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex items-start space-x-3">
            <div>
              <div class="relative px-1">
                <div
                  class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full ring-8 ring-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5 text-gray-500"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex-1 min-w-0 py-0">
              <div class="text-sm leading-8 text-gray-500">
                <span class="mr-0.5">
                  <a
                    href="#"
                    class="font-medium text-gray-900"
                  >Hilary Mahy</a> added tags
                </span>
                <span class="mr-0.5">
                  <a
                    href="#"
                    class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                  >
                    <span
                      class="absolute flex items-center justify-center flex-shrink-0"
                    >
                      <span
                        class="bg-rose-500 h-1.5 w-1.5 rounded-full"
                        aria-hidden="true"
                      />
                    </span>
                    <span class="ml-3.5 font-medium text-gray-900">Needs Work</span>
                  </a>
                  <a
                    href="#"
                    class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                  >
                    <span
                      class="absolute flex items-center justify-center flex-shrink-0"
                    >
                      <span
                        class="bg-indigo-500 h-1.5 w-1.5 rounded-full"
                        aria-hidden="true"
                      />
                    </span>
                    <span class="ml-3.5 font-medium text-gray-900">Urgent</span>
                  </a>
                </span>
                <span class="whitespace-nowrap">6h ago</span>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="relative pb-8">
          <!--v-if-->
          <div class="relative flex items-start space-x-3">
            <div class="relative">
              <img
                class="flex items-center justify-center w-10 h-10 bg-gray-400 rounded-full ring-8 ring-white"
                src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=8&amp;w=256&amp;h=256&amp;q=80"
                alt
              >
            </div>
            <div class="flex-1 min-w-0">
              <div>
                <div class="text-sm">
                  <a
                    href="#"
                    class="font-medium text-gray-900"
                  >Jason Meyers</a>
                </div>
                <p class="mt-0.5 text-sm text-gray-500">
                  Commented 2h ago
                </p>
              </div>
              <div class="mt-2 text-sm text-gray-700">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.</p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- <SidebarCommentInput /> -->
  </div>
</template>
<script>

const SidebarCommentInput = () => import('./SidebarCommentInput')

export default {
  components: {
    SidebarCommentInput
  }
}
</script>
